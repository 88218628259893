import React, { useState, useEffect } from "react";
import { fetchAccountDetails, signInAccount, setDocument } from '../apiService'
import { query, collection, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebaseConfig'
import Logo from "./logo";
import Message from "./message";

export default function CadastrarParecer() {

    const [data, setData] = useState({});
    const [isVisible, setVisible] = useState(false)

    const onHandleSubmitTypeOne = async (e) => {
    e.preventDefault();
    setData({})

    try {
        const palavrasChaveArray = data.palavra_chave.split(';').map((palavra) => palavra.trim());

        // Adicione serverTimestamp para a propriedade 'data_criacao'
        await addDoc(collection(db, 'data_one'), {
            historico: data.historico || '',
            palavra_chave: palavrasChaveArray,
            parecer: data.parecer || '',
            solicitante_parecer: data.solicitante_parecer || '',
            data_criacao: serverTimestamp(),
        });

        setData({});
        setVisible(true);
    } catch (error) {
        console.error('Erro ao salvar dados no Firestore:', error);
    }
}
    const handleOnChange = async (name, value) => {
        setData({ ...data, [name]: value });
    }

    return (
        <section class="bg-gray-50  mt-5">
            <div class="flex flex-col items-center justify-center px-6 py-8 lg:py-0">
                <Logo />
                <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <Message text={'Seu parecer foi cadastrado com sucesso!'} type={'success'} />
                        <form class="space-y-4 md:space-y-6" action="#" onSubmit={(onHandleSubmitTypeOne)}>
                            <div>
                                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Histórico</label>
                                <input type="text" name="historico" onChange={(e) =>
                                    handleOnChange(
                                        e.target.name,
                                        e.target.value
                                    )
                                } value={data.historico || ''} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Palavras Chave</label>
                                <input type="text" name="palavra_chave" onChange={(e) =>
                                    handleOnChange(
                                        e.target.name,
                                        e.target.value
                                    )
                                } value={data.palavra_chave || ''} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Parecer</label>
                                <textarea type="text-area" name="parecer" rows={15} onChange={(e) =>
                                    handleOnChange(
                                        e.target.name,
                                        e.target.value
                                    )
                                } value={data.parecer || ''} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            <div>
                                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Solicitante do parecer</label>
                                <input type="input" name="solicitante_parecer" onChange={(e) =>
                                    handleOnChange(
                                        e.target.name,
                                        e.target.value
                                    )
                                } value={data.solicitante_parecer || ''} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            <button type="submit" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Cadastrar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
