import React, { useState, useEffect } from "react";
import { fetchAccountDetails, signInAccount, setDocument } from '../apiService'
import { db } from '../firebaseConfig'

export default function Logo() {

    return (
        <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white mt-5">
        <img class="w-50 h-50 mr-2" src="https://www.unipac.br/barbacena/wp-content/themes/unipac2017/img/unipac.br-logo.png" width={400} alt="logo" />
      </a>
    )
}