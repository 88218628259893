import React, { useState } from 'react'
import { signInAccount, fetchAccountDetails } from '../apiService'
import { collection, collectionGroup, query, where , getDocs  } from "firebase/firestore";
import { db } from '../firebaseConfig'
import Logo from './logo';
import Message from './message';
import Loading from './loading'

export default function Login({ onHandleContext }) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const signIn = async (e) => {
        e.preventDefault();
        setError(false)
        setLoading(true)

        try {
          const response = await signInAccount(email, password)
          const { uid } = await response.user

          const detailsAccount = await fetchAccountDetails(uid)
          onHandleContext(Number(detailsAccount.account_type))
        }
        catch (e) {
          setError(true)
        }
        finally {
          setLoading(false)
        }

      };

    return (
        <section class="bg-gray-50 ">
        <div class="flex flex-col items-center justify-center px-6 py-8 lg:py-0">
            <Logo />
          <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Base de Pareceres Jurídico - UNIPAC
              </h1>
            {isLoading && <Loading /> }
            <form class="space-y-4 md:space-y-6 sm:max-w-md " action="#" onSubmit={signIn}>
                <div>
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail</label>
                <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>
                <div>
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Senha</label>
                <input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                {error && <Message text="Ocorreu um erro ao realizar autenticação, contate o suporte técnico." type={'error'} /> }
                </div>
                <button type="submit" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Entrar
                </button>
            </form>
            </div>
          </div>
        </div>
      </section>

    )

}