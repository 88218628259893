// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD51QWBiXHs2H78KBuECKvUEiH2UfyeUFg",
  authDomain: "parecer-juridico.firebaseapp.com",
  projectId: "parecer-juridico",
  storageBucket: "parecer-juridico.appspot.com",
  messagingSenderId: "701754269405",
  appId: "1:701754269405:web:089a3a1c1fa9b6e96b8534"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
 
export {
  auth,
  db
}