import React, { useState, useEffect } from "react";
import { fetchAccountDetails, signInAccount, setDocument } from './apiService'
import { collection, collectionGroup, query, where , getDocs  } from "firebase/firestore";
import { db } from './firebaseConfig'
import './App.css';
import Layout from "./layout";
import Login from "./components/login";
import CadastrarParecer from "./components/cadastrar_parecer";
import ConsultarParecer from "./components/consultar_parecer";


function App() {

  const [context, setContext] = useState(0)

  if (context == 0)
    return <Login onHandleContext={setContext} />
  else if (context == 2)
    return <CadastrarParecer />
  else
    return <ConsultarParecer />
}

export default App;
