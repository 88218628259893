import React, { useState, useEffect } from "react";
import { fetchAccountDetails, signInAccount, setDocument } from '../apiService'
import { collection, collectionGroup, query, where, getDocs } from "firebase/firestore";
import Loading from "./loading";
import { db } from '../firebaseConfig'
import Logo from "./logo";
import Message from "./message";
import moment from "moment";
import 'moment/locale/pt-br'

moment.locale(); 

export default function ConsultarParecer() {

    const [buscarParecer, setBuscarParecer] = useState()
    const [resultados, setResultados] = useState([]);
    const [allParecer, setAllParecer] = useState([])
    const [loading, setLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)


    useEffect(() => {
        async function fetchPareceres() {
            const palavrasChaveRef = await collection(db, 'data_one')
            const q = query(palavrasChaveRef);
            const querySnapshot = await getDocs(q);
            setAllParecer(querySnapshot.docs.map(x => x.data()));
        }

        console.log(allParecer)
        fetchPareceres()

    },[])

    const onHandleSubmitTypeTwo = async (e) => {
        e.preventDefault();
        setNotFound(false)
        setResultados([])
        setLoading(true)


        try {

            var searchValue = '';
            if (buscarParecer.includes(','))
                searchValue = buscarParecer.split(',').toString().replace(',', '|')
            else
                searchValue = buscarParecer

            var search = new RegExp(searchValue.replaceAll(' ',''), 'i');

            const result = allParecer.filter((o) => {
                const value = o.palavra_chave.some((keyword) => search.test(keyword))
                return value
            }
            );

            setResultados(result);
            setNotFound(result.length == 0)
            setLoading(false)

        } catch (error) {
            console.error('Erro ao consultar dados no Firestore:', error);
        }
    };

    const renderResultados = () => {

        if (loading) {
            return <Loading />
        }
        else {
            return (
                <React.Fragment>
                    {notFound && <Message text={`Não foi possivel encontrar nenhum parecer com estas palavras chave: ${buscarParecer}`}  type={'error'} /> }
                    <div>
                        <div className=" ">
                            {resultados && resultados.map((resultado, index) => (
                                <div class="rounded overflow-hidden shadow-lg mt-4 border-solid border-2 border-blue-500">
                                    <div class="px-6 py-4">
                                        <div className="flex justify-between items-cente">
                                            <div class="font-bold text-xl mb-2 dark:text-white">{resultado.solicitante_parecer}</div>
                                            <div className="flex gap-2 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                                </svg>
                                                <p className="text-sm text-gray-600">{moment(resultado.data_criacao.toDate().toDateString()).format("LL")}</p>
                                            </div>
                                        </div>
                                        <p class="text-gray-700 text-base dark:text-white">
                                            {resultado.parecer}
                                        </p>
                                    </div>
                                    <div class="px-6 pt-4 pb-2">
                                        {Object.keys(resultado.palavra_chave).map((index) => {
                                            return <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{resultado.palavra_chave[index]}</span>
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )
        }

    }

    return (
        <section class="bg-gray-50 mt-5">
            <div class="flex flex-col items-center justify-center px-6 py-8 lg:py-0">
                <Logo />
                <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form className="space-y-4 md:space-y-6" action="#" onSubmit={onHandleSubmitTypeTwo}>
                            <div>
                                <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Consultar parecer
                                </label>
                                <input
                                    type="text"
                                    name="email"
                                    onChange={(e) => setBuscarParecer(e.target.value)}
                                    value={buscarParecer || ''}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    required
                                />
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-blue-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
                                    </svg>
                                    <p class="text-sm text-blue-500 dark:text-white  px-1 py-2">Separe as palavras chave por virgula.</p>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Consultar
                            </button>
                            {renderResultados()}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )

}
