
import React, { useState, useEffect } from "react";

export default function Loading() {

    return (
        <div className="flex align-items  justify-center">
            <div class="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-blue-600" />
        </div>

    )

}