// Importa as funções necessárias do Firebase
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, collectionGroup, query, where, addDoc, getDocs } from "firebase/firestore";
import { auth, db } from './firebaseConfig';

// Função para realizar o login com e-mail e senha
export const signInAccount = async (email, password) => {
    try {
        // Chama a função de login do Firebase
        return await signInWithEmailAndPassword(auth, email, password);
    }
    catch (error) {
        // Trata erros, se houverem
        console.dir(error);
    }
}

// Função para buscar detalhes da conta com base no UUID do usuário
export const fetchAccountDetails = async (uuid) => {
    // Referência para a coleção de usuários
    const usersRef = collectionGroup(db, 'user');
    
    // Cria uma consulta com a condição de igualdade no UUID
    const q = query(usersRef, where("user_uuid", "==", uuid));
    
    // Executa a consulta
    const querySnapshot = await getDocs(q);

    // Verifica se há resultados
    if (!querySnapshot.empty) {
        // Obtém o primeiro documento retornado
        const snapshot = querySnapshot.docs[0];
        
        // Extrai os dados do documento
        const data = snapshot.data();
        return data;
    }
}

// Função para adicionar um documento com campos específicos
export const setDocument = async (document, fields) => {
    // Adiciona um novo documento à coleção com os campos fornecidos
    const docRef = await addDoc(collection(db, document), fields);
    
    // Loga o ID do documento recém-criado
    console.log("Documento escrito com ID: ", docRef.id);
}

// Função para buscar documentos por palavra-chave em uma coleção
export const fetchDocumentByKeyword = async (document, keyword) => {
    // Referência para a coleção de documentos especificada
    const usersRef = collectionGroup(db, document);
    
    // Cria uma consulta com a condição de igualdade na palavra-chave
    const q = query(usersRef, where("palavra_chave", "==", keyword));
    
    // Executa a consulta
    const querySnapshot = await getDocs(q);

    // Verifica se há resultados
    if (!querySnapshot.empty) {
        // Obtém o primeiro documento retornado
        const snapshot = querySnapshot.docs[0];
        
        // Extrai os dados do documento
        const data = snapshot.data();
        return data;
    }
}
